<template>
  <div class="text-center mt-5">
    <div class="row mt-5 text-center text-light">
      <div class="col-12 mt-5"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
  name: "Campaign",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["DontShowModal"]),
    ...mapMutations(["setCameFromSignUp", "setHideClose"]),
    ...mapMutations("Onboarding", ["set_campaign_code"]),
  },
  computed: {
    this_route_p() {
      return this.$route.params;
    },
    this_route_q() {
      return this.$route.query;
    },
    this_route_h() {
      return this.$route.hash;
    },
  },
  mounted() {
    if (this.$route.query) {
      this.set_campaign_code(this.$route.query.code);

      this.$cookie.set("campaign_code", this.$route.query.code, {
        domain: window.location.hostname,
      });
    }
    this.setCameFromSignUp(true);
    this.$store.commit('DontShowModal')
    this.$store.commit('setHideClose')
    this.$bvModal.show("signup-modal");
    this.$router.push("/");
  },

};
</script>

<style scoped></style>
